import React from 'react';

import { useAppDispatch, useAppSelector } from '@src/hooks';
import { setStakingDrawerOpen } from '@src/features/layout';
import { setModalState } from '@src/features/staking';
import { effectiveApy, parseLockPeriod } from '@src/utils/staking';
import { Icon } from '@src/components/Staking/Icon';

const ModalHeader: React.FC = () => {
    const dispatch = useAppDispatch();

    const { current_pool, pools } = useAppSelector((state) => state.staking);
    const pool = pools.find(({ pool_id }) => pool_id === current_pool);
    const lock_period = parseLockPeriod(pool);

    const { pool_id = '', apr = '0', input_token, reward_token } = pool || {};

    const [current_version] = pool_id?.split(':') || [''];

    const APR =
        current_version === 'compound'
            ? (effectiveApy(parseFloat(apr) / 100, 365) * 100).toFixed(2) + '%'
            : pool?.apr;

    return (
        <div className="bg-staking-header p-4">
            <button
                data-testid="modal-header-btn"
                onClick={() => {
                    dispatch(setModalState('Deposit'));
                    dispatch(setStakingDrawerOpen(false));
                }}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                    />
                </svg>
            </button>

            <div className="flex items-start">
                <Icon
                    input_token={
                        input_token || {
                            address: '',
                            decimals: 18,
                            symbol: '',
                            logo: '',
                        }
                    }
                    reward_token={
                        reward_token || {
                            address: '',
                            decimals: 18,
                            symbol: '',
                            logo: '',
                        }
                    }
                />
                <div className="flex-1 flex">
                    <div className="ml-2 flex-1">
                        <h3
                            data-testid="pool-name"
                            className="text-lg sm:text-2xl"
                        >
                            <strong>{pool?.name}</strong>
                        </h3>
                        <p className="text-xs sm:text-sm">
                            {lock_period} days lock
                        </p>
                    </div>
                    <div className="text-right">
                        <h3 className="text-lg sm:text-2xl">
                            <strong>{APR}</strong>
                        </h3>
                        <p className="text-xs sm:text-sm">
                            {current_version !== 'compound' ? 'Fixed r' : 'R'}
                            eward
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalHeader;
