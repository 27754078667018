import { parseUnits, commify } from '@ethersproject/units';

import { CurrencyInput } from '@src/components/Input';
import { setDepositAmount } from '@src/features/staking';
import { useAppDispatch, useAppSelector } from '@src/hooks';
import {
    parseBalance,
    parseInputValue,
    removeCommas,
    evaluateWeiBalance,
    bnMin,
} from '@src/utils/web3';
import { StakingPool } from '@src/ts/interfaces';
import { ChangeEvent } from '@src/ts/types';
import { DEFAULT_TOKEN } from '@src/config';
import { BigNumber } from '@ethersproject/bignumber';

const { symbol: default_symbol, decimals: default_decimals } = DEFAULT_TOKEN;

const Deposit: React.FC<{
    balance: string;
    deposit_amount: string;
    pool: StakingPool;
}> = ({ balance, deposit_amount, pool }) => {
    const { loading } = useAppSelector((state) => state.staking);
    const dispatch = useAppDispatch();

    const { hardcap, total_staked } = pool;
    const { symbol = default_symbol, decimals = default_decimals } =
        pool?.input_token || {};

    const handleChange = (e: ChangeEvent) => {
        const amount = parseInputValue(e);
        dispatch(setDepositAmount(commify(amount)));
    };

    const handleMaxClick = () => {
        const min = bnMin([
            BigNumber.from(balance),
            BigNumber.from(hardcap).sub(total_staked),
        ]);

        const parsed_balance = parseUnits(
            removeCommas(parseBalance(min, decimals)),
            decimals,
        ).toString();
        return dispatch(
            // eval wei balance will reduce amount if rounded up
            setDepositAmount(evaluateWeiBalance(parsed_balance, balance)),
        );
    };
    const deposit = parseUnits(removeCommas(deposit_amount || '0'), decimals);

    const more_than_available = deposit.gt(balance);

    return (
        <div className="text-left">
            <div className="w-full">
                <CurrencyInput
                    loading={loading}
                    valid={!more_than_available}
                    value={deposit_amount}
                    onChange={handleChange}
                    onMaxClick={handleMaxClick}
                    currency={symbol}
                />
            </div>
            <div className="flex items-center mt-4 text-sm">
                <div className="flex-1 text-sm text-secondary">
                    Available to stake
                </div>
                <strong
                    className={`${more_than_available ? 'text-primary' : ''}`}
                >
                    {`${parseBalance(balance, decimals)} ${symbol}`}
                </strong>
            </div>
        </div>
    );
};

export default Deposit;
