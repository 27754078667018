import Link from 'next/link';
import dayjs from 'dayjs';
import { parseUnits } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';
import { useWeb3Onboard } from '@src/hooks/useWeb3Onboard';

import { useStakingMethods } from '@src/components/Staking/hooks';
import {
    useAppSelector,
    useDecubateContract,
    useTokenApproval,
} from '@src/hooks';

import { DEFAULT_TOKEN } from '@src/config';
import { staking_contract_versions } from '@src/constants';
import { removeCommas } from '@src/utils/web3';

import { ContractType } from '@src/ts/constants';

import { Button } from '../..';

const { symbol: default_symbol, decimals: default_decimals } = DEFAULT_TOKEN;

const FooterButton: React.FC<{
    balance: string;
    deposit_amount: string;
    withdraw_amount: BigNumber;
}> = ({ balance, deposit_amount, withdraw_amount }) => {
    const { account, connect } = useWeb3Onboard();

    const {
        current_pool,
        pools,
        modal_state: current,
    } = useAppSelector((state) => state.staking);

    const pool = pools.find(({ pool_id }) => pool_id === current_pool);
    const [current_version] = pool?.pool_id.split(':') || [''];

    // contract instances
    // eslint-disable-next-line
    const [__, staking_addr] = useDecubateContract(
        staking_contract_versions[current_version],
        true,
    );
    // eslint-disable-next-line
    const [_, compounder_addr] = useDecubateContract(ContractType.Vault, true);

    const {
        decimals = default_decimals,
        symbol = default_symbol,
        address: input_addr,
    } = pool?.input_token || {};

    const cant_withdraw = withdraw_amount.eq(0);
    const cant_deposit = Number(removeCommas(deposit_amount || '0')) === 0;
    const parsed_deposit = parseUnits(
        removeCommas(deposit_amount || '0'),
        decimals,
    );

    const { handleDeposit, handleWithdraw, loading } =
        useStakingMethods(deposit_amount);

    const {
        approved,
        handleApprove,
        loading: approve_loading,
    } = useTokenApproval(
        input_addr,
        account,
        current_version === 'compound' ? compounder_addr : staking_addr,
        parsed_deposit.toString(),
    );

    if (!account) {
        return (
            <Button className="w-full my-4" onClick={() => connect({})}>
                Connect wallet
            </Button>
        );
    }

    const pool_is_expired = dayjs().isAfter(
        dayjs(pool?.end_date || new Date()),
    );

    const deposit = BigNumber.from(parsed_deposit);

    const more_than_available = deposit.gt(balance);

    const deposit_disabled =
        pool_is_expired || cant_deposit || more_than_available;

    return (
        <>
            <div className="flex my-4 space-x-2">
                {current !== 'Withdraw' && (
                    <Button
                        className="flex-1"
                        loading={approve_loading}
                        disabled={
                            approve_loading ||
                            deposit_disabled ||
                            approved ||
                            parsed_deposit.eq(0)
                        }
                        onClick={handleApprove}
                        id="approve"
                    >
                        1. Approve
                    </Button>
                )}
                <Button
                    className="flex-1"
                    id="deposite"
                    disabled={
                        loading ||
                        (current === 'Deposit'
                            ? deposit_disabled || !approved
                            : cant_withdraw)
                    }
                    onClick={
                        current === 'Deposit' ? handleDeposit : handleWithdraw
                    }
                    loading={loading}
                >
                    <strong data-testid="current-version">
                        {current === 'Deposit' && '2.'} {current}
                    </strong>
                </Button>
            </div>

            <Link
                href={`https://pancakeswap.finance/swap?outputCurrency=${input_addr}`}
            >
                <a target="_blank" rel="noreferrer">
                    <Button className="w-full mb-4" dark>
                        Buy {symbol}
                    </Button>
                </a>
            </Link>
        </>
    );
};

export default FooterButton;
