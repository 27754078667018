import React, { useState } from 'react';
import { Transition } from '@headlessui/react';
import { BigNumber } from '@ethersproject/bignumber';

import { useAppDispatch, useAppSelector, useTokenBalance } from '@src/hooks';
import { setStakingDrawerOpen } from '@src/features/layout';
import { setModalState } from '@src/features/staking';

import { hexToRgb } from '@src/utils/device';
import { LEVEL_ONE_COLOR } from '@src/config';

import Deposit from './Deposit';
import FooterButton from './FooterButton';
import HasStakeHeaderText from './HasStakeHeaderText';
import ModalHeader from './ModalHeader';
import SummaryText from './SummaryText';
import Switch from './Switch';

const StakingDrawer: React.FC = () => {
    const [inside_card, setInsideCard] = useState(false);
    const deposit_amount =
        useAppSelector((state) => state?.staking?.deposit_amount) || '0';

    const dispatch = useAppDispatch();

    const {
        current_pool,
        pools,
        modal_state: current,
    } = useAppSelector((state) => state.staking);

    const open = useAppSelector((state) => state?.layout?.staking_modal_open);
    const pool = pools?.find(({ pool_id }) => pool_id === current_pool);

    const { address } = pool?.input_token || {};

    const balance = useTokenBalance(address);

    const withdraw_amount = BigNumber.from(pool?.user_stake || 0);

    const rgb = hexToRgb(LEVEL_ONE_COLOR);

    return (
        <Transition
            data-testid="transition"
            show={open}
            enter="transition-opacity duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className={`min-w-screen fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none text-white overflow-auto`}
            style={{
                backgroundColor: `rgb(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.8)`,
            }}
            onMouseDown={() => {
                if (!inside_card) {
                    dispatch(setStakingDrawerOpen(false));
                    setTimeout(() => dispatch(setModalState('Deposit')), 300);
                }
            }}
        >
            <Transition.Child
                data-testid="transition-child"
                className="text-primary w-full max-w-screen sm:max-w-sm relative ml-auto my-auto shadow-lg bg-dcb min-h-screen border-l-2 border-custom bg-level-one"
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
                onMouseEnter={() => setInsideCard(true)}
                onMouseLeave={() => setInsideCard(false)}
                id="staking-modal"
            >
                <ModalHeader />
                <div className="p-4">
                    {pool?.has_stake && <HasStakeHeaderText />}
                    <div className="bg-level-two p-4 text-center rounded">
                        <Switch />
                        {current === 'Deposit' && (
                            <Deposit
                                deposit_amount={deposit_amount}
                                balance={balance}
                                pool={pool}
                            />
                        )}
                    </div>
                    <SummaryText
                        deposit_amount={deposit_amount}
                        withdraw_amount={withdraw_amount}
                    />
                    <FooterButton
                        balance={balance}
                        deposit_amount={deposit_amount}
                        withdraw_amount={withdraw_amount}
                    />
                </div>
            </Transition.Child>
        </Transition>
    );
};

export default StakingDrawer;
