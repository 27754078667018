import React from 'react';
import dayjs from 'dayjs';
import { BigNumber } from '@ethersproject/bignumber';

import { useAppSelector } from '@src/hooks';

import { estimateReward, parseLockPeriod } from '@src/utils/staking';
import { parseBalance } from '@src/utils/web3';

import { calculateMultiAssetRewards } from './util';

const HasStakeHeaderText: React.FC = () => {
    const { current_pool, pools } = useAppSelector((state) => state.staking);
    const pool = pools.find(({ pool_id }) => pool_id === current_pool);
    const lock_period = parseLockPeriod(pool);

    const {
        pool_id,
        earned_reward = 0,
        user_stake = '0',
        apr,
        ratio = '1',
        is_reward_above_input = false,
        last_user_deposit,
        user_multiplier = '10',
        input_token,
        reward_token,
    } = pool || {};

    const rewards = BigNumber.from(earned_reward);

    const reward = estimateReward(
        user_stake,
        apr,
        lock_period,
        user_multiplier,
        input_token?.decimals,
        reward_token?.decimals,
    );

    const [current_version] = pool_id?.split(':') || [''];

    const reward_with_ratio =
        current_version === 'multi-asset'
            ? calculateMultiAssetRewards(reward, ratio, is_reward_above_input)
            : reward;

    return (
        <>
            <div className="text-sm my-4">
                <div className="flex my-2">
                    <div className="flex-1">
                        <p className="text-secondary">Now staked</p>
                    </div>
                    <strong data-testid="user-stake">
                        {parseBalance(user_stake, input_token?.decimals)}{' '}
                        {input_token?.symbol}
                    </strong>
                </div>

                <div className="flex my-2">
                    <div className="flex-1">
                        <p className="text-secondary">Rewards earned</p>
                    </div>
                    <strong>
                        {parseBalance(rewards, reward_token?.decimals)}{' '}
                        {reward_token?.symbol}
                    </strong>
                </div>

                <div className="flex my-2">
                    <div className="flex-1">
                        <p className="text-secondary">
                            Estimated {lock_period === '1' ? ' daily ' : ''}
                            reward
                        </p>
                    </div>
                    <strong>
                        {parseBalance(
                            reward_with_ratio,
                            reward_token?.decimals,
                        )}
                        {reward_token?.symbol}
                    </strong>
                </div>

                <div className="flex my-2">
                    <div className="flex-1">
                        <p className="text-secondary">Lock end date</p>
                    </div>
                    <strong>
                        {pool?.can_claim
                            ? 'Unlocked'
                            : dayjs(last_user_deposit || new Date())
                                  .add(parseInt(lock_period), 'days')
                                  .format('HH:mm MMM DD, YYYY')}
                    </strong>
                </div>
            </div>
        </>
    );
};

export default HasStakeHeaderText;
