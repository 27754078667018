import { useState } from 'react';
import { parseUnits } from '@ethersproject/units';
import { toast } from 'react-toastify';
import { useWeb3Onboard } from '@src/hooks/useWeb3Onboard';

import {
    useAppDispatch,
    useAppSelector,
    useDecubateContract,
} from '@src/hooks';
import {
    setModalState,
    updatePool,
    setDepositAmount,
} from '@src/features/staking';
import { staking_contract_versions } from '@src/constants';
import { removeCommas } from '@src/utils/web3';
import { ContractType } from '@src/ts/constants';

export const useStakingMethods = (
    deposit_amount: string,
): {
    loading: boolean;
    handleDeposit: () => Promise<void>;
    handleWithdraw: () => Promise<void>;
} => {
    const [loading, setLoading] = useState(false);
    const { account } = useWeb3Onboard();

    const { current_pool, pools } = useAppSelector((state) => state.staking);

    const pool = pools.find(({ pool_id }) => pool_id === current_pool);
    const [current_version, idx] = pool?.pool_id.split(':') || ['', ''];
    const is_compound = current_version === 'compound';

    const { input_token } = pool;

    // contract instances
    const [staking] = useDecubateContract(
        staking_contract_versions[current_version],
        true,
    );
    const [compounder] = useDecubateContract(ContractType.Vault, true);
    const contract = is_compound ? compounder : staking;
    const stake_method = is_compound ? 'deposit' : 'stake';
    const unstake_method = is_compound ? 'withdraw' : 'unStake';
    const unstake_amount = is_compound ? 'user_shares' : 'user_stake';

    const parsed_deposit = parseUnits(
        removeCommas(deposit_amount || '0'),
        input_token.decimals,
    );

    const dispatch = useAppDispatch();

    const handleWithdraw = async () => {
        setLoading(true);
        toast.info('Awaiting successful transaction');
        try {
            const gasLimit = await contract.estimateGas[unstake_method](
                idx,
                pool[unstake_amount],
                { gasLimit: '5000000' },
            );
            const unstake_tx = await contract[unstake_method](
                idx,
                pool[unstake_amount],
                { gasLimit: gasLimit.mul(120).div(100) },
            );
            await unstake_tx.wait();
            toast.success(`Successfully claimed rewards and unstaked`);
            dispatch(updatePool(pool?.pool_id, account));
            dispatch(setModalState('Deposit'));
        } catch (err) {
            toast.error(err.error?.data?.message || err.message);
        }
        setLoading(false);
    };

    const handleDeposit = async () => {
        setLoading(true);
        toast.info('Awaiting successful transaction');
        try {
            // deposit amount in pool
            const gasLimit = await contract.estimateGas[stake_method](
                idx,
                parsed_deposit,
                { gasLimit: '500000' },
            );
            const stake_tx = await contract[stake_method](idx, parsed_deposit, {
                gasLimit: gasLimit.mul(120).div(100),
            });
            await stake_tx.wait();

            toast.success(
                `Successfully deposited ${deposit_amount} ${input_token.symbol}`,
            );
            dispatch(updatePool(pool?.pool_id, account));
            dispatch(setDepositAmount('0'));
        } catch (err) {
            toast.error(err.error?.data?.message || err.message);
        }
        setLoading(false);
    };

    return { handleDeposit, handleWithdraw, loading };
};
