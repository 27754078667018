import { estimateReward, parseLockPeriod } from '@src/utils/staking';
import { parseBalance, removeCommas } from '@src/utils/web3';

import { BigNumber } from '@ethersproject/bignumber';
import { InfoText } from '../..';
import { NFTTooltip } from '@src/components/Staking/NFTTooltip';
import { calculateMultiAssetRewards } from './util';
import dayjs from 'dayjs';
import { parseUnits } from '@ethersproject/units';
import { useAppSelector } from '@src/hooks';

export const TextItem: React.FC<{
    text: string;
    title: string;
    info?: string;
    checked?: boolean;
}> = ({ text, title, info, checked }) => {
    return (
        <div className="flex my-2">
            <div className="flex-1 text-secondary">
                {info ? <InfoText info={info} text={title} /> : title}
            </div>
            <div
                className={`flex items-center space-x-2 ${
                    checked ? 'text-success' : ''
                }`}
            >
                <strong>{text}</strong>
            </div>
        </div>
    );
};

const SummaryText: React.FC<{
    deposit_amount: string;
    withdraw_amount: BigNumber;
}> = ({ deposit_amount, withdraw_amount }) => {
    const {
        current_pool,
        pools,
        modal_state: current,
    } = useAppSelector((state) => state.staking);
    const pool = pools.find(({ pool_id }) => pool_id === current_pool);

    const {
        pool_id = '',
        user_stake = '0',
        earned_reward = '0',
        has_stake,
        apr = '0',
        user_multiplier = '10',
        nft_multiplier = 10,
        nft_multiplier_used,
        ratio = '1',
        is_reward_above_input,
        input_token,
        reward_token,
    } = pool || {};

    const next_balance = BigNumber.from(
        parseUnits(
            removeCommas(deposit_amount || '0'),
            input_token?.decimals || 18,
        ),
    ).add(user_stake);

    const lock_period = parseLockPeriod(pool);

    const boost =
        Math.round(
            ((parseFloat(apr) * nft_multiplier) / 10 - parseFloat(apr)) * 10,
        ) / 10;

    const estimated_reward = estimateReward(
        next_balance,
        apr,
        lock_period,
        user_multiplier,
        input_token?.decimals,
        reward_token?.decimals,
    );

    const [current_version] = pool_id.split(':');

    const reward_with_ratio =
        current_version === 'multi-asset'
            ? calculateMultiAssetRewards(
                  estimated_reward,
                  ratio,
                  is_reward_above_input,
              )
            : estimated_reward;

    const has_boost =
        nft_multiplier_used &&
        BigNumber.from(user_multiplier).eq(nft_multiplier);

    const is_multi_asset = current_version === 'multi-asset';
    const is_compound = current_version === 'compound';

    return (
        (current === 'Deposit' && (
            <div className="my-8">
                <div className="flex my-2">
                    <div className="flex-1 text-secondary">
                        <div className="flex items-center flex-wrap space-x-2">
                            <p>NFT boost</p>
                            <NFTTooltip pool_id={pool_id}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6 text-accent"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                </svg>
                            </NFTTooltip>
                        </div>
                    </div>
                    <div
                        className={`flex items-center space-x-2 ${
                            has_boost ? 'text-success' : ''
                        }`}
                    >
                        <strong>+{has_boost ? boost : 0}%</strong>
                    </div>
                </div>

                {has_stake && (
                    <TextItem
                        title="Stake after deposit"
                        text={`${parseBalance(
                            next_balance.add(!is_compound ? 0 : earned_reward),
                            input_token?.decimals,
                        )} ${input_token?.symbol}`}
                    />
                )}
                <TextItem
                    title={`${has_stake ? 'New e' : 'E'}stimated${
                        lock_period === '1' ? ' daily' : ''
                    } reward`}
                    text={`${parseBalance(
                        reward_with_ratio,
                        reward_token?.decimals,
                    )} ${reward_token?.symbol}`}
                />

                {lock_period !== '' && (
                    <TextItem
                        title={(has_stake ? 'New l' : 'L') + 'ock end date'}
                        text={dayjs()
                            .add(Number(lock_period), 'days')
                            .format('HH:mm MMM DD, YYYY')}
                        info="You can withdraw the deposited amount and rewards after the lock date has expired."
                    />
                )}
            </div>
        )) || (
            <div className="my-4">
                <div className="flex my-2">
                    <div className="flex-1">
                        <p className="text-secondary">Withdraw amount</p>
                    </div>
                    <strong>
                        {parseBalance(
                            withdraw_amount.add(
                                is_multi_asset ? 0 : earned_reward,
                            ),
                            input_token?.decimals,
                        )}{' '}
                        {input_token?.symbol}
                    </strong>
                </div>
                {is_multi_asset && (
                    <div data-testid="multi-asset-div" className="flex my-2">
                        <div className="flex-1">
                            <p className="text-secondary">Reward amount</p>
                        </div>
                        <strong>
                            {parseBalance(
                                earned_reward,
                                reward_token?.decimals,
                            )}
                            {reward_token?.symbol}
                        </strong>
                    </div>
                )}
            </div>
        )
    );
};

export default SummaryText;
